import React, { useState } from "react"
// import React, { useState, useEffect } from 'react'
// import { Link } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import TwoColumn from "../../../components/two-column"
import { ChromePicker } from "react-color"
import Slider from "react-rangeslider"
import "react-rangeslider/lib/index.css"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

var tinycolor = require("tinycolor2")

const bannerContent = {
  title: "Button Generator",
  description: [
    {
      text: `If you're already a Billsby customer and you need help generating some CSS code to make your subscribe buttons and account management buttons look great you're in the right place! Let's get down to business and make some beautiful buttons.`,
    },
  ],
  withPromotion: false,
  textColor: "#252422",
  image: require("../../../images/tools-button-generator.svg"),
  svg: true,
  backgroundColor: "#F3F5FB",
  imageName: "billsby banner image",
  imagePosition: "right",
  hasBg: false,
  isBanner: true,
  generatorBanner: true,
}

const InitialThemeColor = [
  "#73B10E",
  "#D1A700",
  "#006BCF",
  "#D100D1",
  "#BBD400",
  "#00BFA9",
]

const ButtonGenerator = () => {
  const [title, setTitle] = useState("Subscribe today")
  const [subTitle, setSubTitle] = useState("Seven day free trial on all plans")
  const [titleFontSize, setTitleFontSize] = useState("22")
  const [subtitleFontSize, setSubtitleFontSize] = useState("12")
  const [fontColor, setFontColor] = useState("#ffffff")
  const [colorPicker, setColorPicker] = useState("#73B10E")
  const [displayFontColorPicker, setFontDisplayColorPicker] = useState(false)
  const [displayThemeColorPicker, setDisplayThemeColorPicker] = useState(false)
  const [radius, setRadius] = useState(50)
  const [padding, setPadding] = useState(1)
  const [randomClass, setRandomClass] = useState(
    Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, "")
      .substr(0, 4)
  )
  const [displayCode, setDisplayCode] = useState(false)

  const handleOpen = () => {
    if (!displayThemeColorPicker || displayFontColorPicker) {
      setDisplayThemeColorPicker(true)
      setFontDisplayColorPicker(false)
    } else {
      setDisplayThemeColorPicker(false)
    }
  }

  const handleOpenFont = () => {
    if (!displayFontColorPicker || displayThemeColorPicker) {
      setFontDisplayColorPicker(true)
      setDisplayThemeColorPicker(false)
    } else {
      setFontDisplayColorPicker(false)
    }
  }

  const hexToRgb = hex => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null
  }
  const setContrast = rgb =>
    (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000 > 125 ? "black" : "white"

  const getInvertedTheme = setContrast(hexToRgb(colorPicker))
  const getInvertedFont = setContrast(hexToRgb(fontColor))

  let wholePadding = padding * 10

  const tiny_border_DESATURATE = tinycolor(colorPicker)
    .desaturate(55)
    .toString()
  const tiny_text_shadow_DARKEN = tinycolor(colorPicker)
    .darken(20)
    .toString() //15
  const tiny_box_shadow_LIGHTEN = tinycolor(colorPicker)
    .lighten(11)
    .toString()
  const tiny_backgound_image_LIGHTEN_1 = tinycolor(colorPicker)
    .lighten(1)
    .toString()
  const tiny_backgound_image_LIGHTEN_6 = tinycolor(colorPicker)
    .lighten(6.6)
    .desaturate(4)
    .toString()
  const tiny_backgound_image_LIGHTEN_5 = tinycolor(colorPicker)
    .lighten(6)
    .desaturate(4)
    .toString()

  //hover
  const tiny_text_shadow_DARKEN_hover = tinycolor(colorPicker)
    .lighten(3)
    .darken(20)
    .toString()
  const tiny_background_color_LIGHTEN_hover = tinycolor(colorPicker)
    .lighten(3)
    .toString()
  const tiny_box_shadow_LIGHTEN_hover = tinycolor(colorPicker)
    .darken(2)
    .lighten(16)
    .toString()
  const tiny_backgound_image_LIGHTEN_hover_4 = tinycolor(colorPicker)
    .lighten(4)
    .toString()
  const tiny_backgound_image_LIGHTEN_hover_6 = tinycolor(colorPicker)
    .lighten(6.6)
    .desaturate(1)
    .toString()
  const tiny_backgound_image_LIGHTEN_hover_5 = tinycolor(colorPicker)
    .lighten(9)
    .desaturate(4)
    .toString()

  //active
  const tiny_background_color_DARKEN_active = tinycolor(colorPicker)
    .darken(3)
    .toString()
  const tiny_backgound_image_DARKEN_active_5 = tinycolor(colorPicker)
    .darken(5)
    .toString()
  const tiny_backgound_image_LIGHTEN_active_5 = tinycolor(colorPicker)
    .lighten(1)
    .darken(1)
    .toString()

  //border HSL
  const tiny_border_DESATURATE_HSL = tinycolor(
    tiny_border_DESATURATE
  ).toHslString()

  const padding_Final =
    1.2 * wholePadding +
    1 +
    "px " +
    3 * wholePadding +
    "px " +
    (1.2 * wholePadding - 1) +
    "px " +
    3 * wholePadding +
    "px"

  const dynamicStyle = () =>
    css`
      display: inline-block;
      text-align: center;
      border-width: 1px;
      border-style: solid;
      text-transform: uppercase;
      text-decoration: none;
      line-height: 1.1;
      font-weight: normal;
      font-family: sans-serif;
      color: ${fontColor};
      font-size: ${subtitleFontSize}px;
      -webkit-border-radius: ${radius}px;
      -moz-border-radius: ${radius}px;
      border-radius: ${radius}px;
      padding: ${1.2 * wholePadding}px ${3 * wholePadding}px
        ${1.2 * wholePadding}px ${3 * wholePadding}px;
      background-color: ${colorPicker};
      border-color: ${tiny_border_DESATURATE};
      -webkit-text-shadow: 1px 1px 1px ${tiny_text_shadow_DARKEN};
      -moz-text-shadow: 1px 1px 1px ${tiny_text_shadow_DARKEN};
      -o-text-shadow: 1px 1px 1px ${tiny_text_shadow_DARKEN};
      text-shadow: 1px 1px 1px ${tiny_text_shadow_DARKEN};
      box-shadow: inset 0 0 1px 1px ${tiny_box_shadow_LIGHTEN},
        0 0 1px 3px rgba(0, 0, 0, 0.15);
      -webkit-box-shadow: inset 0 0 1px 1px ${tiny_box_shadow_LIGHTEN},
        0 0 1px 3px rgba(0, 0, 0, 0.15);
      -moz-box-shadow: inset 0 0 1px 1px ${tiny_box_shadow_LIGHTEN},
        0 0 1px 3px rgba(0, 0, 0, 0.15);
      background-image: linear-gradient(
        top,
        ${tiny_backgound_image_LIGHTEN_1} 0%,
        ${tiny_backgound_image_LIGHTEN_6} 48%,
        ${colorPicker} 49%,
        ${colorPicker} 82%,
        ${tiny_backgound_image_LIGHTEN_5} 100%
      );
      background-image: -webkit-linear-gradient(
        top,
        ${tiny_backgound_image_LIGHTEN_1} 0%,
        ${tiny_backgound_image_LIGHTEN_6} 48%,
        ${colorPicker} 49%,
        ${colorPicker} 82%,
        ${tiny_backgound_image_LIGHTEN_5} 100%
      );
      background-image: -moz-linear-gradient(
        top,
        ${tiny_backgound_image_LIGHTEN_1} 0%,
        ${tiny_backgound_image_LIGHTEN_6} 48%,
        ${colorPicker} 49%,
        ${colorPicker} 82%,
        ${tiny_backgound_image_LIGHTEN_5} 100%
      );
      background-image: -o-linear-gradient(
        top,
        ${tiny_backgound_image_LIGHTEN_1} 0%,
        ${tiny_backgound_image_LIGHTEN_6} 48%,
        ${colorPicker} 49%,
        ${colorPicker} 82%,
        ${tiny_backgound_image_LIGHTEN_5} 100%
      );
      :hover {
        color: ${fontColor};
        background-color: ${tiny_background_color_LIGHTEN_hover};
        background-image: -webkit-linear-gradient(
          top,
          ${tiny_backgound_image_LIGHTEN_hover_4} 0%,
          ${tiny_backgound_image_LIGHTEN_hover_6} 48%,
          ${tiny_background_color_LIGHTEN_hover} 49%,
          ${tiny_background_color_LIGHTEN_hover} 82%,
          ${tiny_backgound_image_LIGHTEN_hover_5} 100%
        );
        background-image: -moz-linear-gradient(
          top,
          ${tiny_backgound_image_LIGHTEN_hover_4} 0%,
          ${tiny_backgound_image_LIGHTEN_hover_6} 48%,
          ${tiny_background_color_LIGHTEN_hover} 49%,
          ${tiny_background_color_LIGHTEN_hover} 82%,
          ${tiny_backgound_image_LIGHTEN_hover_5} 100%
        );
        background-image: -o-linear-gradient(
          top,
          ${tiny_backgound_image_LIGHTEN_hover_4} 0%,
          ${tiny_backgound_image_LIGHTEN_hover_6} 48%,
          ${tiny_background_color_LIGHTEN_hover} 49%,
          ${tiny_background_color_LIGHTEN_hover} 82%,
          ${tiny_backgound_image_LIGHTEN_hover_5} 100%
        );
        background-image: linear-gradient(
          top,
          ${tiny_backgound_image_LIGHTEN_hover_4} 0%,
          ${tiny_backgound_image_LIGHTEN_hover_6} 48%,
          ${tiny_background_color_LIGHTEN_hover} 49%,
          ${tiny_background_color_LIGHTEN_hover} 82%,
          ${tiny_backgound_image_LIGHTEN_hover_5} 100%
        );
        border-color: ${tiny_border_DESATURATE};
        -webkit-box-shadow: inset 0 0 1px 1px ${tiny_box_shadow_LIGHTEN_hover},
          0 0 1px 3px rgba(0, 0, 0, 0.15);
        -moz-box-shadow: inset 0 0 1px 1px ${tiny_box_shadow_LIGHTEN_hover},
          0 0 1px 3px rgba(0, 0, 0, 0.15);
        box-shadow: inset 0 0 1px 1px ${tiny_box_shadow_LIGHTEN_hover},
          0 0 1px 3px rgba(0, 0, 0, 0.15);
        -webkit-text-shadow: 1px 1px 1px ${tiny_text_shadow_DARKEN_hover};
        -moz-text-shadow: 1px 1px 1px ${tiny_text_shadow_DARKEN_hover};
        -o-text-shadow: 1px 1px 1px ${tiny_text_shadow_DARKEN_hover};
        text-shadow: 1px 1px 1px ${tiny_text_shadow_DARKEN_hover};
      }
      :active {
        padding: ${padding_Final};
        color: ${fontColor};
        background-color: ${tiny_background_color_DARKEN_active};
        background-image: -webkit-linear-gradient(
          top,
          ${tiny_backgound_image_DARKEN_active_5} 0%,
          ${tiny_backgound_image_LIGHTEN_active_5} 100%
        );
        background-image: -moz-linear-gradient(
          top,
          ${tiny_backgound_image_DARKEN_active_5} 0%,
          ${tiny_backgound_image_LIGHTEN_active_5} 100%
        );
        background-image: -o-linear-gradient(
          top,
          ${tiny_backgound_image_DARKEN_active_5} 0%,
          ${tiny_backgound_image_LIGHTEN_active_5} 100%
        );
        background-image: linear-gradient(
          top,
          ${tiny_backgound_image_DARKEN_active_5} 0%,
          ${tiny_backgound_image_LIGHTEN_active_5} 100%
        );
      }
    `

  const Button = styled.button`
    ${dynamicStyle};
  `

  const strongStyle = {
    fontSize: titleFontSize + "px",
  }

  const handleGetCode = () => {
    const random = Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, "")
      .substr(0, 4)
    setRandomClass(random)
    setDisplayCode(true)
  }

  return (
    <Layout>
      <SEO
        title="Button Generator"
        description="Generate CSS code to make your subscribe buttons and account management buttons look great with our free button generator."
        url="https://www.billsby.com/resources/tools/buttongenerator"
      />
      <TwoColumn content={bannerContent} alt="sample" />
      <div className="button-generator">
        <div className="container">
          <div className="titles-wrapper">
            <div className="titles">
              <div className="inputs">
                <p className="label">Title</p>
                <input
                  type="text"
                  className="input-title input-field"
                  value={title}
                  onChange={e => setTitle(e.target.value)}
                />
              </div>
              <div className="inputs">
                <p className="label">
                  Subtitle <span>(optional)</span>
                </p>
                <input
                  type="text"
                  className="input-title input-field"
                  value={subTitle}
                  onChange={e => setSubTitle(e.target.value)}
                />
              </div>
            </div>
            <div className="font-size">
              <p className="label font">Font Size</p>
              <input
                type="number"
                className="size input-field"
                min="0"
                value={titleFontSize}
                onChange={e => setTitleFontSize(e.target.value)}
              />
              <input
                type="number"
                className="size input-field"
                min="0"
                value={subtitleFontSize}
                onChange={e => setSubtitleFontSize(e.target.value)}
              />
            </div>
            <div className="font-color">
              <p className="label color">Font Color</p>
              {/* <input type="text" className="size"/> */}
              <div className="picker-holder">
                <button
                  className="colorbtn"
                  onClick={handleOpenFont}
                  style={{ backgroundColor: fontColor, color: getInvertedFont }}
                >
                  {fontColor}
                </button>
                {displayFontColorPicker && (
                  <div className="pop-over">
                    <ChromePicker
                      color={fontColor}
                      onChange={color => setFontColor(color.hex)}
                      disableAlpha="true"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="theme-color">
            <p className="label">Theme Color</p>
            <div className="btn-colors">
              {InitialThemeColor.map(color => (
                <button
                  key={color}
                  className="swatch"
                  value={color}
                  style={{ backgroundColor: color }}
                  onClick={e => setColorPicker(e.target.value)}
                >
                  {""}
                </button>
              ))}
            </div>
            <div className="picker-holder">
              <button
                className="color"
                onClick={handleOpen}
                style={{
                  backgroundColor: colorPicker,
                  color: getInvertedTheme,
                }}
              >
                {colorPicker}
              </button>
              {displayThemeColorPicker && (
                <div className="pop-over">
                  <ChromePicker
                    color={colorPicker}
                    onChange={color => setColorPicker(color.hex)}
                    disableAlpha="true"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="range">
            <p className="label">Border Radius</p>
            <div className="range-holder">
              <Slider
                min={0}
                max={50}
                value={radius}
                orientation="horizontal"
                onChange={value => setRadius(value)}
              />
            </div>
            <div className="range-value">
              <input
                className="input-field"
                type="number"
                value={radius}
                min="0"
                onChange={e => setRadius(e.target.value)}
              />
            </div>
          </div>

          <div className="range">
            <p className="label">Padding Multiply</p>
            <div className="range-holder">
              <Slider
                min={0}
                max={2}
                step={0.1}
                value={padding}
                orientation="horizontal"
                onChange={value => setPadding(value.toFixed(1))}
              />
            </div>
            <div className="range-value">
              <input
                className="input-field"
                type="number"
                value={padding}
                min="0"
                onChange={e => setPadding(e.target.value)}
              />
            </div>
          </div>
          <div className="field-preview">
            <Button as="a" href="" onClick={e => e.preventDefault()}>
              <strong style={strongStyle}>{title}</strong>
              <br />
              {subTitle}
            </Button>
          </div>
          <div className="getcode_holder">
            <button className="btn-orange" onClick={handleGetCode}>
              Get code
            </button>
          </div>
          {displayCode ? (
            <div className="generated-code">
              <p className="label">
                Button code - Add this wherever you want the button to appear
              </p>
              <p className="sub-label" style={{ paddingTop: "20px" }}>
                Subscribe Button
              </p>
              <pre>
                &lt;a href="javascript:void:(0)" class="billsbybutton-
                {randomClass}" data-billsby-type="checkout"&gt;&lt;strong&gt;
                {title}&lt;/strong&gt;&lt;br /&gt;{subTitle}&lt;/a&gt;
              </pre>
              <p className="sub-label">Account management button</p>
              <pre>
                &lt;a href="javascript:void:(0)" class="billsbybutton-
                {randomClass}" data-billsby-type="account"&gt;&lt;strong&gt;
                {title}&lt;/strong&gt;&lt;br /&gt;{subTitle}&lt;/a&gt;
              </pre>

              <p className="label" style={{ paddingTop: "20px" }}>
                <head> Header code - Add this before the closing </head> tag in
                your HTML
              </p>
              <p className="sub-label" style={{ paddingTop: "20px" }}>
                You'll need this code <strong>in addition to</strong> the header
                code we ask you to include in the Billsby Control Panel. You can
                put it either before or after.
              </p>

              <pre>
                <code>
                  &lt;style type="text/css" media="screen"&gt; <br />
                  .billsbybutton-{randomClass} &#123; <br />
                  &nbsp;&nbsp;display: inline-block;
                  <br />
                  &nbsp;&nbsp;text-align: center;
                  <br />
                  &nbsp;&nbsp;border-width: 1px;
                  <br />
                  &nbsp;&nbsp;border-style: solid;
                  <br />
                  &nbsp;&nbsp;text-transform: uppercase;
                  <br />
                  &nbsp;&nbsp;text-decoration: none;
                  <br />
                  &nbsp;&nbsp;line-height: 1.1;
                  <br />
                  &nbsp;&nbsp;font-weight: normal;
                  <br />
                  &nbsp;&nbsp;font-family: sans-serif;
                  <br />
                  &nbsp;&nbsp;color: {fontColor};<br />
                  &nbsp;&nbsp;font-size: {subtitleFontSize}px;
                  <br />
                  &nbsp;&nbsp;background-color: {colorPicker};<br />
                  &nbsp;&nbsp;background-image: -webkit-linear-gradient(top,{" "}
                  {tiny_backgound_image_LIGHTEN_1} 0%,{" "}
                  {tiny_backgound_image_LIGHTEN_6} 48%, {colorPicker} 49%,{" "}
                  {colorPicker} 82%, {tiny_backgound_image_LIGHTEN_5} 100%);
                  <br />
                  &nbsp;&nbsp;background-image: -moz-linear-gradient(top,{" "}
                  {tiny_backgound_image_LIGHTEN_1} 0%,{" "}
                  {tiny_backgound_image_LIGHTEN_6} 48%, {colorPicker} 49%,{" "}
                  {colorPicker} 82%, {tiny_backgound_image_LIGHTEN_5} 100%);
                  <br />
                  &nbsp;&nbsp;background-image: -o-linear-gradient(top,{" "}
                  {tiny_backgound_image_LIGHTEN_1} 0%,{" "}
                  {tiny_backgound_image_LIGHTEN_6} 48%, {colorPicker} 49%,{" "}
                  {colorPicker} 82%, {tiny_backgound_image_LIGHTEN_5} 100%);
                  <br />
                  &nbsp;&nbsp;background-image: linear-gradient(top,{" "}
                  {tiny_backgound_image_LIGHTEN_1} 0%,{" "}
                  {tiny_backgound_image_LIGHTEN_6} 48%, {colorPicker} 49%,{" "}
                  {colorPicker} 82%, {tiny_backgound_image_LIGHTEN_5} 100%);
                  <br />
                  &nbsp;&nbsp;border-color: {tiny_border_DESATURATE_HSL};<br />
                  &nbsp;&nbsp;-webkit-box-shadow: inset 0 0 1px 1px{" "}
                  {tiny_box_shadow_LIGHTEN}, 0 0 1px 3px rgba(0, 0, 0, 0.15);
                  <br />
                  &nbsp;&nbsp;-moz-box-shadow: inset 0 0 1px 1px{" "}
                  {tiny_box_shadow_LIGHTEN}, 0 0 1px 3px rgba(0, 0, 0, 0.15);
                  <br />
                  &nbsp;&nbsp;box-shadow: inset 0 0 1px 1px{" "}
                  {tiny_box_shadow_LIGHTEN}, 0 0 1px 3px rgba(0, 0, 0, 0.15);
                  <br />
                  &nbsp;&nbsp;-webkit-text-shadow: 1px 1px 1px{" "}
                  {tiny_text_shadow_DARKEN};<br />
                  &nbsp;&nbsp;-moz-text-shadow: 1px 1px 1px{" "}
                  {tiny_text_shadow_DARKEN};<br />
                  &nbsp;&nbsp;-o-text-shadow: 1px 1px 1px{" "}
                  {tiny_text_shadow_DARKEN};<br />
                  &nbsp;&nbsp;text-shadow: 1px 1px 1px {tiny_text_shadow_DARKEN}
                  ;<br />
                  &nbsp;&nbsp;-webkit-border-radius: {radius}px;
                  <br />
                  &nbsp;&nbsp;-moz-border-radius: {radius}px;
                  <br />
                  &nbsp;&nbsp;border-radius: {radius}px;
                  <br />
                  &nbsp;&nbsp;padding: {1.2 * wholePadding}px {3 * wholePadding}
                  px {1.2 * wholePadding}px {3 * wholePadding}px;
                  <br />
                  &#125;
                  <br />
                  <br />
                  .billsbybutton-{randomClass} strong &#123; <br />
                  &nbsp;&nbsp;letter-spacing: 1px;
                  <br />
                  &nbsp;&nbsp;font-size: {titleFontSize}px;
                  <br />
                  &#125;
                  <br />
                  <br />
                  .billsbybutton-{randomClass}:hover &#123; <br />
                  &nbsp;&nbsp;color: {fontColor};<br />
                  &nbsp;&nbsp;background-color:{" "}
                  {tiny_background_color_LIGHTEN_hover};<br />
                  &nbsp;&nbsp;background-image: -webkit-linear-gradient(top,{" "}
                  {tiny_backgound_image_LIGHTEN_hover_4} 0%,{" "}
                  {tiny_backgound_image_LIGHTEN_hover_6} 48%,{" "}
                  {tiny_background_color_LIGHTEN_hover} 49%,{" "}
                  {tiny_background_color_LIGHTEN_hover} 82%,{" "}
                  {tiny_backgound_image_LIGHTEN_hover_5} 100%);
                  <br />
                  &nbsp;&nbsp;background-image: -moz-linear-gradient(top,{" "}
                  {tiny_backgound_image_LIGHTEN_hover_4} 0%,{" "}
                  {tiny_backgound_image_LIGHTEN_hover_6} 48%,{" "}
                  {tiny_background_color_LIGHTEN_hover} 49%,{" "}
                  {tiny_background_color_LIGHTEN_hover} 82%,{" "}
                  {tiny_backgound_image_LIGHTEN_hover_5} 100%);
                  <br />
                  &nbsp;&nbsp;background-image: -o-linear-gradient(top,{" "}
                  {tiny_backgound_image_LIGHTEN_hover_4} 0%,{" "}
                  {tiny_backgound_image_LIGHTEN_hover_6} 48%,{" "}
                  {tiny_background_color_LIGHTEN_hover} 49%,{" "}
                  {tiny_background_color_LIGHTEN_hover} 82%,{" "}
                  {tiny_backgound_image_LIGHTEN_hover_5} 100%);
                  <br />
                  &nbsp;&nbsp;background-image: linear-gradient(top,{" "}
                  {tiny_backgound_image_LIGHTEN_hover_4} 0%,{" "}
                  {tiny_backgound_image_LIGHTEN_hover_6} 48%,{" "}
                  {tiny_background_color_LIGHTEN_hover} 49%,{" "}
                  {tiny_background_color_LIGHTEN_hover} 82%,{" "}
                  {tiny_backgound_image_LIGHTEN_hover_5} 100%);
                  <br />
                  &nbsp;&nbsp;border-color: {tiny_border_DESATURATE};<br />
                  &nbsp;&nbsp;-webkit-box-shadow: inset 0 0 1px 1px{" "}
                  {tiny_box_shadow_LIGHTEN_hover}, 0 0 1px 3px rgba(0, 0, 0,
                  0.15);
                  <br />
                  &nbsp;&nbsp;-moz-box-shadow: inset 0 0 1px 1px{" "}
                  {tiny_box_shadow_LIGHTEN_hover}, 0 0 1px 3px rgba(0, 0, 0,
                  0.15);
                  <br />
                  &nbsp;&nbsp;box-shadow: inset 0 0 1px 1px{" "}
                  {tiny_box_shadow_LIGHTEN_hover}, 0 0 1px 3px rgba(0, 0, 0,
                  0.15);
                  <br />
                  &nbsp;&nbsp;-webkit-text-shadow: 1px 1px 1px{" "}
                  {tiny_text_shadow_DARKEN_hover};<br />
                  &nbsp;&nbsp;-moz-text-shadow: 1px 1px 1px{" "}
                  {tiny_text_shadow_DARKEN_hover};<br />
                  &nbsp;&nbsp;-o-text-shadow: 1px 1px 1px{" "}
                  {tiny_text_shadow_DARKEN_hover};<br />
                  &nbsp;&nbsp;text-shadow: 1px 1px 1px{" "}
                  {tiny_text_shadow_DARKEN_hover};<br />
                  &#125;
                  <br />
                  <br />
                  .billsbybutton-{randomClass}:active &#123; <br />
                  &nbsp;&nbsp;padding: {padding_Final};<br />
                  &nbsp;&nbsp;color: {fontColor};<br />
                  &nbsp;&nbsp;background-color:{" "}
                  {tiny_background_color_DARKEN_active};<br />
                  &nbsp;&nbsp;background-image: -webkit-linear-gradient(top,{" "}
                  {tiny_backgound_image_DARKEN_active_5} 0%,{" "}
                  {tiny_backgound_image_LIGHTEN_active_5} 100%);
                  <br />
                  &nbsp;&nbsp;background-image: -moz-linear-gradient(top,{" "}
                  {tiny_backgound_image_DARKEN_active_5} 0%,{" "}
                  {tiny_backgound_image_LIGHTEN_active_5} 100%);
                  <br />
                  &nbsp;&nbsp;background-image: -o-linear-gradient(top,{" "}
                  {tiny_backgound_image_DARKEN_active_5} 0%,{" "}
                  {tiny_backgound_image_LIGHTEN_active_5} 100%);
                  <br />
                  &nbsp;&nbsp;background-image: linear-gradient(top,{" "}
                  {tiny_backgound_image_DARKEN_active_5} 0%,{" "}
                  {tiny_backgound_image_LIGHTEN_active_5} 100%);
                  <br />
                  &#125;
                  <br />
                  &lt;/style&gt;
                </code>
              </pre>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </Layout>
  )
}

export default ButtonGenerator
